import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {ConversationMessageResponse, ConversationSummary, ConversationUserSummary} from "../data/ConversationResponse";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useTheme,
    Select,
    MenuItem,
    FormControl,
    Snackbar,
    Alert
} from "@mui/material";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {LoadingContent} from "../components/LoadingContent";
import {useNavigate, useParams} from "react-router-dom";
import {formatTimestamp, generateProfilePath, parseIntOrNull} from "../utils";
import {TextEditor} from "../components/editor/TextEditor";
import {EditorContent} from "../components/editor/EditorContent";
import {RankChip} from "../components/RankChip";
import AddIcon from '@mui/icons-material/Add';
import {UserResponse} from "../data/UserResponse";
import {RankResponse} from "../data/RankResponse";
import {DivisionRoles} from "../data/DivisionRoles";
import {UserSelector} from "../components/UserSelector";
import {BlockUi} from "../components/BlockUi";
import useUser from "../hooks/useUser";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MailIcon from '@mui/icons-material/Mail';
import DraftsIcon from '@mui/icons-material/Drafts';
import {ConfirmationDialog} from "../components/ConfirmationDialog";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {MiniProfileTarget} from "../components/MiniProfileTarget";
import {PoliceUser} from "../components/PoliceUser";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import {AlertType} from "../data/AlertType";
import GroupAddIcon from '@mui/icons-material/GroupAdd';

interface ConversationSummaryLineProps {
    currentlySelected: boolean
    conversationSummary: ConversationSummary
    onConversationSelected: () => void
}

const ConversationSummaryLine = (props: ConversationSummaryLineProps) => {
    const theme = useTheme()
    let selectedColor
    if (props.currentlySelected) {
        selectedColor = theme.palette.mode === "light" ? theme.palette.grey["200"] : theme.palette.grey["800"]
    }
    const hoverColor = theme.palette.mode === "light" ? theme.palette.grey["300"] : theme.palette.grey["700"]
    let icon;
    if (props.conversationSummary.read) {
        icon = <DraftsIcon fontSize="small"/>
    } else {
        icon = <MailIcon fontSize="small" color="info"/>
    }

    return (
        <Box onClick={props.onConversationSelected} sx={{
            cursor: "pointer",
            backgroundColor: selectedColor,
            "&:hover": {
                backgroundColor: hoverColor
            }
        }}>
            <Stack direction="row" sx={{ml: 2}} spacing={2} alignItems="center">
                {icon}
                <Typography sx={{fontWeight: (props.currentlySelected) ? "bold" : "regular"}}
                            variant="subtitle1">{props.conversationSummary.title}</Typography>
            </Stack>
        </Box>
    )
}


interface InboxDialogProps extends PropsWithChildren {
    title: string
    buttonText: string
    open: boolean
    onClose: () => void
    onButtonClicked: () => void
}

const InboxDialog = (props: InboxDialogProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onButtonClicked()}>{props.buttonText}</Button>
            </DialogActions>
        </Dialog>
    )
}

interface ConversationsPanelProps {
    conversationSummaries: ConversationSummary[] | null
    currentlySelected: ConversationSummary | null
    refreshConversations: () => void
}

const ConversationsPanel = (props: ConversationsPanelProps) => {
    const navigate = useNavigate()

    const selectConversation = useCallback((id: number) => {
        navigate(`/inbox/${id}`)
    }, [navigate])

    const [dialogOpen, setDialogOpen] = useState(false)
    const [title, setTitle] = useState("")
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);

    return (
        <Paper variant={"outlined"}>
            <InboxDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false)
                    setTitle("")
                }}
                onButtonClicked={() => {
                    if (title.trim() === "") return
                    setTitle("")
                    setDialogOpen(false)
                    setApiCall(makeApiCall<ConversationSummary>({
                        url: `/api/conversation`,
                        method: "POST",
                        body: {title: title},
                        onLoadedCallback: (newConversation) => {
                            selectConversation(newConversation.id)
                            props.refreshConversations()
                        },
                        onError: () => {
                        }
                    }))
                }}
                buttonText="Create" title="Create Conversation"
            >
                <TextField
                    autoFocus
                    margin="dense"
                    label="Title"
                    fullWidth
                    variant="standard"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                />
                <Typography variant="caption">
                    A brief description of the conversation. Messages and participants are added later.
                </Typography>
            </InboxDialog>
            <Stack sx={{pt: 2, pb: 2}}>
                <Stack sx={{ml: 2, mr: 2}} direction="row">
                    <Typography variant="h5">Inbox</Typography>
                    <IconButton sx={{ml: "auto"}} onClick={() => setDialogOpen(true)}>
                        <AddIcon/>
                    </IconButton>
                </Stack>
                <Divider sx={{mt: 1, mb: 1}}/>
                <Stack sx={{maxHeight: 300, overflow: "auto"}}>
                    {props.conversationSummaries && props.conversationSummaries.map(row => {
                        return <ConversationSummaryLine key={row.id} conversationSummary={row}
                                                        onConversationSelected={() => selectConversation(row.id)}
                                                        currentlySelected={props.currentlySelected?.id === row.id}
                        />
                    })}
                </Stack>
            </Stack>
        </Paper>
    )
}

interface ConversationMessageProps {
    message: ConversationMessageResponse
}

const ConversationMessage = (props: ConversationMessageProps) => {
    const user = props.message.user
    const navigate = useNavigate()
    const onClick = () => {
        navigate(generateProfilePath(user.communityId))
    }

    return (
        <Paper variant={"outlined"} sx={{p: 2}}>
            <Stack>
                <Box display="flex" flexWrap="wrap" gap={1} sx={{m: 1}}>
                    <RankChip rank={user.rank.displayName} color={user.rank.color} onClick={onClick}/>
                    <MiniProfileTarget communityId={user.communityId}>
                        <RankChip rank={user.nick} onClick={onClick}/>
                    </MiniProfileTarget>
                    <RankChip rank={user.rpName} color="23C6C8" onClick={onClick}/>
                    <Box style={{flexGrow: 1}}/>
                    <Typography variant="subtitle1">
                        {formatTimestamp(props.message.date)}
                    </Typography>
                </Box>
                <Divider sx={{mb: 1}}/>
                <EditorContent content={props.message.content}/>
            </Stack>
        </Paper>
    )
}

interface MessagesPanelProps {
    conversation: ConversationSummary
    refreshConversations: () => void
}

const MessagesPanel = (props: MessagesPanelProps) => {
    const loadedMessages = useApiCall<ConversationMessageResponse[]>({
        url: `/api/conversation/${props.conversation.id}/messages`,
    })

    useEffect(() => {
        if (loadedMessages.isLoading || loadedMessages.isError || !loadedMessages.data) return
        if (!props.conversation.read) {
            props.refreshConversations()
        }
    }, [loadedMessages.data, loadedMessages.isLoading, loadedMessages.isError, props])
    
    const user = useUser()
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [dialogVisible, setDialogVisible] = useState(false)
    const [leaveApiCall, setLeaveApiCall] = useState<ApiCallResponseData>()
    const navigate = useNavigate()

    const cancelOldLeaveApiCall = useCallback(() => {
        leaveApiCall?.cancel()
    }, [leaveApiCall])

    const cancelOldSubmitApiCall = useCallback(() => {
        apiCall?.cancel()
    }, [apiCall])

    useEffect(() => {
        return cancelOldSubmitApiCall
    }, [cancelOldSubmitApiCall])

    useEffect(() => {
        return cancelOldLeaveApiCall
    }, [cancelOldLeaveApiCall])


    const onSubmitPressed = (html: string) => {
        let conversationId = props.conversation?.id
        if (!conversationId) return
        cancelOldSubmitApiCall()
        setApiCall(makeApiCall({
            url: `/api/conversation/${conversationId}/messages`,
            method: "POST",
            body: {content: html},
            onLoadedCallback: () => {
                loadedMessages.refresh()
            },
            onError: () => {
            }
        }))
    }

    const onLeaveConfirmed = () => {
        let conversationId = props.conversation?.id
        if (!conversationId) return
        cancelOldLeaveApiCall()
        setLeaveApiCall(makeApiCall({
            url: `/api/conversation/${props.conversation.id}/participant/${user.user?.communityId}`,
            method: "DELETE",
            onLoadedCallback: () => {
                navigate("/inbox")
                props.refreshConversations()
            },
            onError: () => {
            }
        }))
    }

    const lockConversation = () => {
        makeApiCall({
            url: `/api/conversation/${props.conversation.id}/lock/`,
            method: "PATCH",
            body: {id: props.conversation.id, locked: true},
            onLoadedCallback: () => {
                props.refreshConversations()
            },
            onError: () => {
            }
        })
    }

    const unlockConversation = () => {
        makeApiCall({
            url: `/api/conversation/${props.conversation.id}/lock/`,
            method: "PATCH",
            body: {id: props.conversation.id, locked: false},
            onLoadedCallback: () => {
                props.refreshConversations()
            },
            onError: () => {
            }
        })
    }

    return (
        <Paper variant={"outlined"}>
            <ConfirmationDialog title="Leave Conversation"
                                description="Are you sure you want to leave the conversation?" open={dialogVisible}
                                onClose={(confirmed) => {
                                    setDialogVisible(false)
                                    if (confirmed) {
                                        onLeaveConfirmed()
                                    }
                                }}/>
            <Stack spacing={2}>
                <Stack spacing={0.5} sx={{pl: 4, pr: 4, pt: 2}}>
                    <Box sx={{display: "flex"}}>
                        <Typography variant="h4">
                            {props.conversation.title}
                        </Typography>
                        <div style={{flexGrow: 1}}/>
                        {(user.hasPermissions("conversation:lock") && !props.conversation.locked) || (props.conversation?.creator?.communityId === user.user?.communityId && !props.conversation.locked) ? <Tooltip title="Lock Conversation" onClick={() => lockConversation()}><IconButton><LockOutlinedIcon/></IconButton></Tooltip> : (user.hasPermissions("conversation:lock") || (props.conversation?.creator?.communityId === user.user?.communityId)) && <Tooltip title="Unlock Conversation" onClick={() => unlockConversation()}><IconButton><LockOpenOutlinedIcon/></IconButton></Tooltip>}
                        <Tooltip title="Leave Conversation" onClick={() => setDialogVisible(true)}>
                            <IconButton>
                                <ExitToAppIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Stack direction="row" sx={{ml: 1, mr: 1}} spacing={0.5} alignItems="center">
                        <PersonOutlineIcon sx={{fontSize: 20, mr: 0.5}}/>
                        <PoliceUser linkProps={{variant: "subtitle1"}} user={props.conversation.creator}/>
                        <Typography variant={"subtitle1"} sx={{pl: 0.5, pr: 0.5}}>
                            •
                        </Typography>
                        <AccessTimeIcon sx={{fontSize: 18}}/>
                        <Typography variant={"subtitle1"}>
                            {formatTimestamp(props.conversation.createdAt)}
                        </Typography>
                    </Stack>
                </Stack>
                <Divider/>
                <LoadingContent isLoading={loadedMessages.isLoading}>
                    <Stack spacing={2} sx={{p: 2}}>
                        {loadedMessages.data && loadedMessages.data.map(message => {
                            return <ConversationMessage key={message.id} message={message}/>
                        })}
                        {!props.conversation.locked ? <TextEditor onSubmitPressed={onSubmitPressed} submitButtonText="Post Message"/> : <></>}
                    </Stack>
                </LoadingContent>
            </Stack>
        </Paper>
    )
}

interface ConversationParticipantLineProps {
    participant: ConversationUserSummary
    showDeleteButton: boolean
    onDeletePressed: () => void
}

const ConversationParticipantLine = (props: ConversationParticipantLineProps) => {
    const navigate = useNavigate()
    const theme = useTheme()
    const hoverColor = theme.palette.mode === "light" ? theme.palette.grey["200"] : theme.palette.grey["800"]
    return (
        <Box sx={{
            "&:hover": {
                backgroundColor: hoverColor
            },
            pl: 1,
            pr: 1,
            pb: 0.5,
            pt: 0.5
        }}>
            <Stack direction="row" spacing={0.5} alignItems="center">
                <MiniProfileTarget communityId={props.participant.communityId}>
                    <RankChip rank={props.participant.nick}
                              color={props.participant.rank.color}
                              onClick={() => {
                                  navigate(generateProfilePath(props.participant.communityId))
                              }}
                    />
                </MiniProfileTarget>
                {props.showDeleteButton &&
                    <IconButton size="small" color="error" onClick={props.onDeletePressed}>
                        <RemoveCircleOutlineIcon fontSize="small"></RemoveCircleOutlineIcon>
                    </IconButton>
                }
            </Stack>
        </Box>
    )
}

interface ParticipantsPanelProps {
    conversation: ConversationSummary
    ranks: RankResponse[]
    roles: DivisionRoles[]
}

const ParticipantsPanel = (props: ParticipantsPanelProps) => {
    const ownUserData = useUser()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogRankOpen, setRankDialogOpen] = useState(false)
    const [dialogRoleOpen, setRoleDialogOpen] = useState(false)
    const [user, setUser] = useState<UserResponse | null>(null)
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [removeApiCall, setRemoveApiCall] = useState<ApiCallResponseData>()
    const [userToRemove, setUserToRemove] = useState<ConversationUserSummary>()
    const [removeDescription, setRemoveDescription] = useState("")
    const conversationId = props.conversation.id
    const [rankId, setRankId] = useState(-1)
    const [roleId, setRoleId] = useState(-1)
    let url
    if (props.conversation) {
        url = `/api/conversation/${props.conversation.id}/participant`
    }
    const loadedParticipants = useApiCall<ConversationUserSummary[]>({
        url: url,
    })
    const isOwnConversation = props.conversation?.creator?.communityId === ownUserData.user?.communityId
    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall])
    useEffect(() => {
        return () => {
            removeApiCall?.cancel()
        }
    }, [removeApiCall])

    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    return (
        <Paper variant={"outlined"}>
            <ConfirmationDialog title={"Remove from Conversation"}
                                description={removeDescription}
                                open={userToRemove !== undefined}
                                onClose={(confirmed) => {
                                    const communityId = userToRemove?.communityId
                                    setUserToRemove(undefined)
                                    if (!communityId || !confirmed) return
                                    setRemoveApiCall(makeApiCall({
                                        url: `/api/conversation/${conversationId}/participant/${communityId}`,
                                        method: "DELETE",
                                        onLoadedCallback: () => {
                                            loadedParticipants.refresh()
                                            setRemoveApiCall(undefined)
                                            setAlert("success", "User removed from conversation")
                                        },
                                        onError: () => {
                                            setRemoveApiCall(undefined)
                                            setAlert("error", "Failed to remove user from conversation")
                                        }
                                    }))
                                }}></ConfirmationDialog>
            <InboxDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false)
                    setUser(null)
                }}
                onButtonClicked={() => {
                    const communityId = user?.communityId
                    if (!communityId) return
                    setApiCall(makeApiCall({
                        url: `/api/conversation/${conversationId}/participant/${communityId}`,
                        method: "PUT",
                        onLoadedCallback: () => {
                            setUser(null)
                            setDialogOpen(false)
                            loadedParticipants.refresh()
                            setApiCall(undefined)
                            setAlert("success", "User added to conversation")
                        },
                        onError: () => {
                            setApiCall(undefined)
                            setAlert("error", "Failed to add user to conversation")
                        }
                    }))
                }}
                buttonText="Invite" title="Invite to the Conversation"
            >
                <BlockUi open={apiCall != null}>
                    <Typography variant="subtitle2" sx={{mb: 1}}>
                        Who do you want to add to the conversation?
                    </Typography>
                    <UserSelector user={user} extendedUserInformation={true} onUserSelected={setUser}></UserSelector>
                </BlockUi>
            </InboxDialog>
            <InboxDialog
                open={dialogRankOpen}
                onClose={() => {
                    setRankDialogOpen(false)
                    setRankId(-1)
                }}
                onButtonClicked={() => {
                    setApiCall(makeApiCall({
                        url: `/api/conversation/${conversationId}/participant/rank/${rankId}`,
                        method: "PUT",
                        body: {id: props.conversation.id, rankId: rankId},
                        onLoadedCallback: () => {
                            setRankId(-1)
                            setRankDialogOpen(false)
                            loadedParticipants.refresh()
                            setApiCall(undefined)
                            setAlert("success", "Rank added to conversation")
                        },
                        onError: () => {
                            setApiCall(undefined)
                            setAlert("error", "Failed to add rank to conversation")
                        }
                    }))
                    apiCall?.cancel()
                }}
                buttonText="Invite Ranks" title="Invite Ranks to the Conversation"
            >
                <BlockUi open={apiCall !== undefined}>
                    <Typography variant="subtitle2" sx={{mb: 1}}>
                        Which Ranks do you want to add to the conversation?
                    </Typography>
                    <FormControl fullWidth>
                    <Select id="rank-select" value={rankId} onChange={(event) => {setRankId(event.target.value as number)}}>
                        {props.ranks?.map((ranks) =>
                            <MenuItem key={ranks.rankId} value={ranks.rankId}>{ranks.displayName}</MenuItem>
                        )}
                    </Select>
                    </FormControl>
                </BlockUi>
            </InboxDialog>
            <InboxDialog
            open={dialogRoleOpen}
            onClose={() => {
                setRoleDialogOpen(false)
                setRoleId(-1)
            }}
            onButtonClicked={() => {
                setApiCall(makeApiCall({
                    url: `/api/conversation/${conversationId}/participant/role/${roleId}`,
                    method: "PUT",
                    onLoadedCallback: () => {
                        setRoleId(-1)
                        setRoleDialogOpen(false)
                        loadedParticipants.refresh()
                        setApiCall(undefined)
                        setAlert("success", "Role added to conversation")
                    },
                    onError: () => {
                        setApiCall(undefined)
                        setAlert("error", "Failed to add role to conversation")
                    }
                }))
            }}
            buttonText="Invite Roles" title="Invite Roles to the Conversation"
            >
            <BlockUi open={apiCall !== undefined}>
                <Typography variant="subtitle2" sx={{mb: 1}}>
                    Which Roles do you want to add to the conversation?
                </Typography>
                <FormControl fullWidth>
                <Select id="role-select" value={roleId} onChange={(event) => {setRoleId(event.target.value as number)}}>
                    {props.roles?.map((roles) =>
                        <MenuItem key={roles.roleId} value={roles.roleId}>{roles.displayName}</MenuItem>
                    )}
                </Select>
                </FormControl>
            </BlockUi>
            </InboxDialog>
            <LoadingContent isLoading={loadedParticipants.isLoading}>
                <Stack sx={{pt: 2, pb: 2, overflow: "auto"}}>
                    <Stack sx={{ml: 2, mr: 2}} direction="row">
                        <Typography variant="h5">Participants</Typography>
                        <IconButton sx={{ml: "auto"}} onClick={() => setDialogOpen(true)}>
                            <AddIcon/>
                        </IconButton>
                        {(ownUserData.user?.permissions.find(p => {return p.includes("mass-add")}) !== undefined)
                        ? <IconButton onClick={() => setRoleDialogOpen(true)}>
                        <GroupAddIcon/>
                        </IconButton> : <></>}
                        {(ownUserData.hasPermissions("conversation:add-ranks"))
                        ? <IconButton onClick={() => setRankDialogOpen(true)}>
                        <AddModeratorIcon/>
                        </IconButton> : <></>}
                    </Stack>
                    <Divider sx={{mt: 1, mb: 1}}/>

                    <Stack sx={{maxHeight: 300, overflow: "auto"}}>
                        {loadedParticipants.data && loadedParticipants.data.map(participant => {
                            return <ConversationParticipantLine
                                key={participant.communityId}
                                participant={participant}
                                showDeleteButton={(participant.communityId !== ownUserData.user?.communityId && isOwnConversation) || (ownUserData.hasPermissions("conversation:lock"))}
                                onDeletePressed={() => {
                                    setUserToRemove(participant)
                                    setRemoveDescription(`Are you sure you want to remove ${participant.nick} from this conversation?`)
                                }}
                            />
                        })}
                    <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                        <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                            {alertText}
                        </Alert>
                    </Snackbar>
                    </Stack>
                </Stack>
            </LoadingContent>
        </Paper>
    )
}

export const InboxPage = () => {
    const params = useParams()
    const navigate = useNavigate()

    let paramId = parseIntOrNull(params.id ?? "")
    const conversationSummaries = useApiCall<ConversationSummary[]>({
        url: "/api/conversation"
    })

    const loadRanks = useApiCall<RankResponse[]>({
        initialUrl: `/api/rank/conversation-rank-list`
    })

    const loadRoles = useApiCall<DivisionRoles[]>({
        initialUrl: `/api/conversation/mass-inbox-roles-list`
    })

    useEffect(() => {
        if (!paramId && conversationSummaries.data && !conversationSummaries.isLoading && conversationSummaries.data.length > 0) {
            navigate(`/inbox/${conversationSummaries.data[0].id}`)
        }
    }, [conversationSummaries.data, conversationSummaries.isLoading, navigate, paramId]);
    const currentConversation = conversationSummaries.isLoading ? null : conversationSummaries.data?.find(a => a.id === paramId) ?? null


    return (
        <Grid container spacing={4} columns={{xs: 3, lg: 12}}>
            <Grid item xs={3}>
                <Stack spacing={4}>
                    <ConversationsPanel conversationSummaries={conversationSummaries.data}
                                        currentlySelected={currentConversation}
                                        refreshConversations={() => conversationSummaries.refresh()}/>
                    {currentConversation && <ParticipantsPanel conversation={currentConversation} ranks={loadRanks.data!} roles={loadRoles.data!}/>}
                </Stack>
            </Grid>
            <Grid item xs={3} lg={9}>
                {currentConversation && <MessagesPanel refreshConversations={() => conversationSummaries.refresh()}
                                                       conversation={currentConversation}/>}
            </Grid>
        </Grid>
    )
}